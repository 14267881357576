@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  --color1: #1a2c5b; /*dark blue*/
  --color2: #2b4b87; /*regular blue*/
  --color3: #431488; /* dark purple*/
  --color4: #b8dff0; /*light blue*/
  --color5: #ffffff; /*white*/
  --color6: #c0c0c0; /*silver*/
  --color7: #e5e5e5; /*light grey*/
  --color8: #6a26cd; /*regular purple*/
  --color9: #808080; /*grey*/
  --primary050: hsla(270, 100%, 98%, 1);
  --primary100: hsla(269, 100%, 95%, 1);
  --primary200: hsla(269, 100%, 92%, 1);
  --primary300: hsla(269, 97%, 85%, 1);
  --primary400: hsla(270, 95%, 75%, 1);
  --primary500: hsla(271, 91%, 65%, 1);
  --primary600: hsla(271, 81%, 56%, 1);
  --primary700: hsla(272, 72%, 47%, 1);
  --primary800: hsla(273, 67%, 37%, 1);
  --primary900: hsla(274, 66%, 32%, 1);
  --grey050: hsla(210, 40%, 98%, 1);
  --grey100: hsla(210, 40%, 96%, 1);
  --grey200: hsla(214, 32%, 91%, 1);
  --grey300: hsla(213, 27%, 84%, 1);
  --grey400: hsla(215, 20%, 65%, 1);
  --grey500: hsla(215, 16%, 47%, 1);
  --grey600: hsla(215, 19%, 35%, 1);
  --grey700: hsla(215, 25%, 27%, 1);
  --grey800: hsla(217, 33%, 17%, 1);
  --grey900: hsla(222, 47%, 11%, 1);
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body,
input,
button,
select,
textarea {
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-style: normal;
  color: var(--grey800);
  background-color: var(--grey050);
}

a {
  text-decoration: none;
}

/* a:link {
  color: var(--color8);
}

a:hover {
  color: var(--color5);
}

a:active {
  color: var(--color8);
}

a:visited {
  color: var(--color8);
} */

.no-scroll {
  overflow: hidden;
}
